import React, { Component } from 'react';
//Elements
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
//Images
import Logo from '../images/logo.svg';

class Navigationbar extends Component {
  render() { return(
    <div>
    <Navbar variant="dark" expand="lg" className="d-flex justify-content-center">
    <Navbar.Brand className="d-lg-none" href="/"> <img src={Logo} className="logo" alt="logo" /></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto mr-auto">
    <Nav.Link href="/standup/">Standup</Nav.Link>
    <Nav.Link href="/books/" className="link2">Books</Nav.Link>
    <Navbar.Brand className="d-none d-lg-block" href="/"><img src={Logo} className="logo" alt="logo" /></Navbar.Brand>
    <Nav.Link href="/podcasts/">Podcast</Nav.Link>
    <Nav.Link href="/contact/">Contact</Nav.Link>
    </Nav>
    </Navbar.Collapse>
    </Navbar>
    </div>
  );
}
}

export default Navigationbar;
