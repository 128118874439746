import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
//images
import manlyQuotesOne from '../images/manly-quotes.png';
import manlyQuotesTwo from '../images/manly-quotes2.png';
import justDoItDamnIt from '../images/just-do-it-damn-it.png';
import warriorCultures from '../images/warrior-cultures.png';
import lettersToSon from '../images/letters-to-son.png';
import fiveDaysOfScenery from '../images/five-days-of-scenery.png';

class Bookspage extends Component {
  render() { return(
    <div className="Home">

    <Helmet>
    <title>Mike Yanek - Liverpool based comedian & podcaster</title>
    <meta name="description" content="When inspiration strikes, I'm known to write books about various things - quotes, Samurai, fictional family members. You can find my books on Amazon." />
    <meta property="og:title" content="Mike Yanek - Liverpool based comedian & podcaster" />
    <meta property="og:description" content="When inspiration strikes, I'm known to write books about various things - quotes, Samurai, fictional family members. You can find my books on Amazon." />
    <meta property="og:image" content="/og-image.jpg"/>
    </Helmet>

    <Container>

    <Row className="book-row">
    <Col lg="4">
    <img src={fiveDaysOfScenery} className="img-fluid p-5" alt="Five Days of Scenery: Journey Across a Small Village (Novel)"/>
    </Col>
    <Col lg="4">
    <h2 className="mt-3 book-title">Five Days of Scenery: Journey Across a Small Village (Novel)</h2>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.co.uk/gp/product/B07V65FF3N/ref=dbs_a_def_rwt_hsch_vapi_taft_p1_i0" className="book-button">Amazon UK</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.com/gp/product/B07V65FF3N/ref=dbs_a_def_rwt_bibl_vppi_i5 " className="book-button">Amazon US</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.ca/Five-Days-Scenery-Journey-Village-ebook/dp/B07V65FF3N/ref=sr_1_2?qid=1582822057&refinements=p_27%3AMike+Yanek&s=digital-text&sr=1-2&text=Mike+Yanek" className="book-button">Amazon CA</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.de/gp/product/B08269FLS7/ref=dbs_a_def_rwt_bibl_vppi_i0" className="book-button">Amazon DE</Button>
    </Col>
    <Col lg="4">
      <p className="synopsis mt-3">These are the personal writings of James Elmswood, an American journalist, who met and fell in love with the woman of his dreams, the beautiful Kalina, after she moved to the States to pursue her studies in higher education. Their romance blossomed, and after ten years of being together (five years married), it’s time for the San Diego-based couple to visit Kalina’s birthplace; a tiny village on the other side of the globe, located deep in the mountainous region of the Balkans (South-East Europe). James’ diary records all of his encounters over the five days he spent there in July of 1985.</p>
    </Col>
    </Row>

    <Row className="book-row">
    <Col lg="4">
    <img src={warriorCultures} className="img-fluid p-5" alt="Warrior Cultures of the World: Samurai, Immortal, Spartan, Maori"/>
    </Col>
    <Col lg="4">
    <h2 className="mt-3 book-title">Warrior Cultures of the World: Samurai, Immortal, Spartan, Maori</h2>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.co.uk/gp/product/B07NC8PFP8/ref=dbs_a_def_rwt_hsch_vapi_taft_p1_i5" className="book-button">Amazon UK</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.com/gp/product/B07NC8PFP8/ref=dbs_a_def_rwt_bibl_vppi_i1" className="book-button">Amazon US</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.ca/Warrior-Cultures-World-Samurai-Immortal-ebook/dp/B07NC8PFP8/ref=sr_1_3?qid=1582821478&refinements=p_27%3AMike+Yanek&s=digital-text&sr=1-3&text=Mike+Yanek" className="book-button">Amazon CA</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.de/gp/product/B07NC8PFP8/ref=dbs_a_def_rwt_bibl_vppi_i3" className="book-button">Amazon DE</Button>
    </Col>
    <Col lg="4">
      <p className="synopsis mt-3">In this book, we will be presenting four of the most fascinating, exciting and mysterious ancient warrior cultures from around the world. We wanted to combine four warrior cultures that are completely different from each other in their approach to life, warfare and weaponry; groups which have left very different cultural heritage behind. We decided to present two very mainstream and popular cultures - the Samurai and the Spartan, and to two lesser known ones - the Immortal and the Maori. If you don’t know much about these cultures, this will be a great way for you to learn all the basics that you need in a very quick and fun way.</p>
    </Col>
    </Row>

    <Row className="book-row">
    <Col lg="4">
    <img src={lettersToSon} className="img-fluid p-5" alt="The Challenge Ahead: Letters to a Son In Need"/>
    </Col>
    <Col lg="4">
    <h2 className="mt-3 book-title">The Challenge Ahead: Letters to a Son In Need</h2>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.co.uk/gp/product/B08269FLS7/ref=dbs_a_def_rwt_hsch_vapi_taft_p1_i4" className="book-button">Amazon UK</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.com/gp/product/B08269FLS7/ref=dbs_a_def_rwt_bibl_vppi_i4" className="book-button">Amazon US</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.ca/Challenge-Ahead-Letters-Son-Need-ebook/dp/B08269FLS7/ref=sr_1_1?qid=1582821564&refinements=p_27%3AMike+Yanek&s=digital-text&sr=1-1&text=Mike+Yanek" className="book-button">Amazon CA</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.de/gp/product/B08269FLS7/ref=dbs_a_def_rwt_bibl_vppi_i0" className="book-button">Amazon DE</Button>
    </Col>
    <Col lg="4">
      <p className="synopsis mt-3">A young son, living in a time far distant from our present, chased down by a horde of unfortunate circumstances was he. Guidance he sought from his wise and experienced father; fortunate was he, such a person to have in his life. In written form that advice was given, shaped in the style of a letter, the blank canvas of each paper verbally painted in a beautiful way; slightly poetic I may say. Views on many different topics were expressed; sage advice gathered over the decades, an elder handed down to his son. Letters to a son in need, all of them you can find here.</p>
    </Col>
    </Row>

    <Row className="book-row">
    <Col lg="4">
    <img src={manlyQuotesOne} className="img-fluid p-5" alt="The Book of Manly Quotes Volume 1"/>
    </Col>
    <Col lg="4">
    <h2 className="mt-3 book-title">The Book of Manly Quotes: 200 Quotes on Masculinity, Success and Happiness</h2>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.co.uk/gp/product/179281237X/ref=dbs_a_def_rwt_hsch_vapi_taft_p1_i2" className="book-button">Amazon UK</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.com/gp/product/179281237X/ref=dbs_a_def_rwt_bibl_vppi_i0" className="book-button">Amazon US</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.ca/Book-Manly-Quotes-Masculinity-Happiness/dp/179281237X/ref=sr_1_6?keywords=mike+yanek&qid=1582821008&sr=8-6" className="book-button">Amazon CA</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.de/gp/product/179281237X/ref=dbs_a_def_rwt_bibl_vppi_i5" className="book-button">Amazon DE</Button>
    </Col>
    <Col lg="4">
      <p className="synopsis mt-3">A book purposefully focused on manly quotes during times when young men are experiencing a masculine identity crisis the likes of which we have never seen before. Sometimes a few inspiring words and ideas can be the spark of a new beginning. So, mid-2017, I thought why not select 200 quotes and create a book for people to read. So, a few months later, here we are. I hope you enjoy this book and I am looking forward to your responses. I hope that this book will help and inspire you, just like it will help and inspire my future son one day!</p>
    </Col>
    </Row>

    <Row className="book-row">
    <Col lg="4">
    <img src={manlyQuotesTwo} className="img-fluid p-5" alt="The Book of Manly Quotes Volume 2"/>
    </Col>
    <Col lg="4">
    <h2 className="mt-3 book-title">The Book of Manly Quotes Part 2: 200 Quotes on Leadership, Warfare & Love</h2>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.co.uk/gp/product/B07NHTYG8Y/ref=dbs_a_def_rwt_hsch_vapi_taft_p1_i3" className="book-button">Amazon UK</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.com/gp/product/B07NHTYG8Y/ref=dbs_a_def_rwt_bibl_vppi_i2" className="book-button">Amazon US</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.ca/Book-Manly-Quotes-Part-Leadership-ebook/dp/B07NHTYG8Y/ref=sr_1_5?keywords=mike+yanek&qid=1582821008&sr=8-5" className="book-button">Amazon CA</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.de/gp/product/B07NHTYG8Y/ref=dbs_a_def_rwt_bibl_vppi_i2 " className="book-button">Amazon DE</Button>
    </Col>
    <Col lg="4">
      <p className="synopsis mt-3">A book purposefully focused on manly quotes during times when young men are experiencing a masculine identity crisis the likes of which we have never seen before. Sometimes a few inspiring words and ideas can be the spark of a new beginning. So, mid-2018, I thought why not select 200 more  quotes and create a book for people to read. So, a few months later, here we are. I hope you enjoy this book and I am looking forward to your responses. I hope that this book will help and inspire you, just like it will help and inspire my future son one day!</p>
    </Col>
    </Row>



    <Row className="book-row">
    <Col lg="4">
    <img src={justDoItDamnIt} className="img-fluid p-5" alt="Just Do It - Damn It: The Approach To Life, Business & Success"/>
    </Col>
    <Col lg="4">
    <h2 className="mt-3 book-title">Just Do It - Damn It: The Approach To Life, Business & Success</h2>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.co.uk/gp/product/B00BPSCBZI/ref=dbs_a_def_rwt_hsch_vapi_taft_p1_i1" className="book-button">Amazon UK</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.com/gp/product/B00BPSCBZI/ref=dbs_a_def_rwt_bibl_vppi_i3" className="book-button">Amazon US</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.ca/Just-Do-Approach-Business-Success-ebook/dp/B00BPSCBZI/ref=sr_1_4?keywords=mike+yanek&qid=1582821008&sr=8-4" className="book-button">Amazon CA</Button>
        <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.amazon.de/gp/product/B00BPSCBZI/ref=dbs_a_def_rwt_bibl_vppi_i4" className="book-button">Amazon DE</Button>
    </Col>
    <Col lg="4">
      <p className="synopsis mt-3">Short and straight to the point (just like me). One time I heard somebody say “You can spend all your life reading and studying on how to do push-ups, but if you never actually practice, you will never be able to do any.” This quote had a very big part in the reason why you hold this paperback right now. This book is created with one goal in mind. Get some information and inspiration and go in to the world and practice it for yourself. Failure or success it does not matter, just sum up the courage to make the first step. If you are consistent enough you will improve with time.</p>
    </Col>
    </Row>

    </Container>

    </div>
  );
}
}

export default Bookspage;
