import React, { Component } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });
    return (
      <div
        style={{
          background: "#efefef",
          borderRadius: 2,
          padding: 10,
          display: "inline-block"
        }}
      >
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <input
          style={{ fontSize: "2em", padding: 5 }}
          ref={node => (email = node)}
          type="email"
          placeholder="Your email"
        />
        <br />
        <button style={{ fontSize: "2em", padding: 5 }} onClick={submit}>
          Submit
        </button>
      </div>
    );
  };

  class mailchimpSubscribe extends Component {
  render() {
    const url =
      "https://mikeyanek.us19.list-manage.com/subscribe/post?u=3197f3e469305dc4cac0d7de1&amp;id=7a6be57565";
    return (
      <div>
        <MailchimpSubscribe url={url} />
      </div>
    );
  }
}

export default mailchimpSubscribe;
