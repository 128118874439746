import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';

class Socialbar extends Component {
  render() { return(
<div className="social-bar hide-lg-down text-right d-none d-lg-block">
    <a href="https://www.facebook.com/MikeYanekShow" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faFacebook}/></a>
    <a href="https://www.instagram.com/mikeyanek/?hl=en" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faInstagram}/></a>
    <a href="https://twitter.com/MikeYanek" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faTwitter}/></a>
    <a href="https://www.youtube.com/channel/UCaqSNurL3xHioYcaMkjUX1A" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faYoutube}/></a>
</div>
);
}
}

export default Socialbar;
