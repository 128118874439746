import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';

class ContactPage extends Component {
  render() { return(
    <div className="privacy-policy">

    <Helmet>
    <title>Mike Yanek - Liverpool based comedian & podcaster</title>
    <meta name="description" content="Comedian, podcaster and a shameless self promoter based in Liverpool UK. If you want to get in touch with me, e-mail me or message me on one of my social media channels." />
    <meta property="og:title" content="Mike Yanek - Liverpool based comedian & podcaster" />
    <meta property="og:description" content="Comedian, podcaster and a shameless self promoter based in Liverpool UK. If you want to get in touch with me, e-mail me or message me on one of my social media channels." />
    <meta property="og:image" content="/og-image.jpg"/>
    </Helmet>
    <Container>
    <Row className="contact-row">
    <Col className="text-center">
      <h3 className="mt-2">Email</h3>
      <span className="flow-text mb-1"><a href="mailto:mikeyanek@yahoo.com" target="_blank" rel="noopener noreferrer" >mikeyanek@yahoo.com</a></span>
      <h3 className="mt-2">Facebook</h3>
      <span className="flow-text mb-1"><a href="https://www.facebook.com/MikeYanekShow" target="_blank" rel="noopener noreferrer" >@MikeYanekShow</a></span>
      <h3 className="mt-2">Instagram</h3>
      <span className="flow-text mb-1"><a href="https://www.instagram.com/mikeyanek" target="_blank" rel="noopener noreferrer" >@mikeyanek</a></span>
      <h3 className="mt-2">Twitter</h3>
      <span className="flow-text mb-3"><a href="https://twitter.com/MikeYanek" target="_blank" rel="noopener noreferrer" >@MikeYanek</a></span>
    </Col>
    </Row>
    </Container>
    </div>
  );
}
}

export default ContactPage;
