import React, { Component } from 'react';
//Includes
import Cookienotice from '../includes/cookienotice.js';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';

class Footer extends Component {
  render() { return(
    <div>
    <footer className="footer">
    <Container>
    <Row>
    <Col className="d-flex justify-content-center">
    <span className="text-center mt-4 mb-4" id="copyright">Copyright &copy; 2020 Mike Yanek<br/><br/><a href="/privacy-policy">Privacy Policy</a></span>
    </Col>
    </Row>
    <Row>
      <Col className="social-footer d-flex justify-content-center">
      <a href="https://www.facebook.com/MikeYanekShow"><FontAwesomeIcon icon={faFacebook}/></a>
      <a href="https://www.instagram.com/mikeyanek/?hl=en"><FontAwesomeIcon icon={faInstagram}/></a>
      <a href="https://twitter.com/MikeYanek"><FontAwesomeIcon icon={faTwitter}/></a>
      <a href="www.youtube.com/channel/UCaqSNurL3xHioYcaMkjUX1A"><FontAwesomeIcon icon={faYoutube}/></a>
      </Col>
    </Row>
    </Container>
    <Cookienotice/>
    </footer>
    </div>
  );
}
}

export default Footer;
