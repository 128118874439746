import React, { Component } from 'react';
//Packages
import { CookieBanner } from '@palmabit/react-cookie-law';
//Analytics - Trackers and so forth
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

class Cookienotice extends Component {
  render() { return(
    <CookieBanner
    message="Like most websites we would like to set cookies to understand more about our website visitors and to improve our advertising, in line with our privacy policy. You can select which cookies you’re okay for us to set with these checkboxes or click the “privacy policy” link to read more."
    onAccept = {() => {}}
    onAcceptPreferences = {() => {}}
    onAcceptStatistics = {() => {ReactGA.initialize('UA-119130804-1');ReactGA.pageview(window.location.pathname + window.location.search);}}
    onAcceptMarketing = {() => {ReactPixel.init('######');ReactPixel.pageView();}}
    showPreferencesOption = {false}
    statisticsOptionText	= "Analytics"
    policyLink = "/privacy-policy"
    styles={{
      dialog: {     position: 'fixed',
      left: '0px',
      right: '0px',
      bottom: '0px',
      zIndex: '100000',
      backgroundColor: '#000000',
      padding: '10px', },
      message: {
        color: '#ffffff',
      },
      policy: {
        color: '#ffffff',
        textDecoration: 'underline',
      },
      optionLabel: {
        height: 'auto',
        width: 'auto',
        minHeight: '14px',
        fontSize: '12pt',
        color: '#ffffff',
        display: 'inline-block',
        padding: '1px 0px 0px 20px',
        position: 'relative',
        top: '0px',
        left: '0px',
        zIndex: '1',
        cursor: 'default',
        verticalAlign: 'top',
      }
    }}
    />
  );
}
}

export default Cookienotice;
