import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';

class Privacypage extends Component {
  render() { return(
    <div className="privacy-policy">

    <Helmet>
    <title>Mike Yanek - Liverpool based comedian & podcaster</title>
    <meta name="description" content="Welcome to the tiny legal world of Mike Yanek's privacy policy. Read on and if you have any questions e-mail me." />
    <meta property="og:title" content="Mike Yanek - Liverpool based comedian & podcaster" />
    <meta property="og:description" content="Welcome to the tiny legal world of Mike Yanek's privacy policy. Read on and if you have any questions e-mail me." />
    <meta property="og:image" content="/og-image.jpg"/>
    </Helmet>
    <Container>
    <Row>
    <Col className="text-center">
    <h1> Privacy Policy</h1>
<p>Here is my Privacy Policy. There’s nothing unusual about it and nothing to be concerned about.</p>
<p>Your use of this site, in any and all forms, constitutes an acceptance of this Privacy Policy.</p>
<h1>Log files</h1>
<p>Like many other sites I use certain data like log files to analyse and optimise my blog’s performance. These log files include – IP, type of browser, date and time stamp, referring and exit pages, number of clicks. All this information is not linked to anything that is personally identifiable.</p>
<h1>Cookies</h1>
<p>This blog does not share personal information with third parties nor do we store any information about your visit to this blog other than to analyze and optimize your content and reading experience through the use of cookies.</p>
<p>You can turn off the use of cookies at any time by changing your specific browser settings.</p>
<p>This blog uses Google’s cookie. Google is a third-party vendor, which uses the cookie to serve ads on this site. This cookie enables Google to serve these ads to you based on your visit to this blog and/or other blogs on the internet.</p>
<p>You can find more information about this here <a href="https://policies.google.com/privacy/partners">https://policies.google.com/privacy/partners</a> and here <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads.</a></p>
<h1>Others</h1>
<p>Users might be asked to subscribe to my newsletter by providing name and email address to receive communication from Mikeyanek.com. I use a secure opt-in subscription system and reserve the right to contact subscribers with information related to this website and blog. Subscribers may unsubscribe any time and every email delivered will contain an “Unsubscribe” link.</p>
<p>Your e-mails will never be shared with a third party and will not be used for any other purpose except the above stated one.<br />
We are not responsible for republished content from this blog on other blogs or websites without our permission.</p>
<p>This privacy policy is subject to change without notice and was last updated on 20/03/2020. If you have any questions, feel free to contact me directly here:mikeyanek@yahoo.com</p>

    </Col>
    </Row>
    </Container>


    </div>
  );
}
}

export default Privacypage;
