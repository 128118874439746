//This pulls specific blog posts in the events category from the wordPress API.
import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import axios from "axios";
//Images
import MikeOnMic from '../images/mike-on-mic.jpg'

class Standuppage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
    this.createMarkup = this.createMarkup.bind();
  }

  componentDidMount() {
    axios.get("https://mikeyanek.com/wp/wp-json/wp/v2/posts/?categories=406&per_page=10").then(posts => {
      this.setState({
        posts: posts.data
      });
    });
  }

  createMarkup(html) {
    return { __html: html };
  }


  render() { return(
    <div className="Home">

    <Helmet>
    <title>Mike Yanek - Liverpool based comedian & podcaster</title>
    <meta name="description" content="You're crazy about my lovable persona? Good news, you can catch me at a gig across the North. See my schedule to find which venues I'll be at." />
    <meta property="og:title" content="Mike Yanek - Liverpool based comedian & podcaster" />
    <meta property="og:description" content="You're crazy about my lovable persona? Good news, you can catch me at a gig across the North. See my schedule to find which venues I'll be at." />
    <meta property="og:image" content="/og-image.jpg"/>
    </Helmet>
      <Container>
      <Row>
      <Col md="4" className="d-none d-md-block">
      <img src={MikeOnMic} className="mike-on-mic mt-4" alt="Mike Yanek Performing" />
      </Col>
      <Col md="8" className="text-center mt-4">
      <h1 className="mb-4 gig-title">Upcoming Gigs</h1>
      {this.state.posts.map(post => (
        <div key={post.id}>
      <h4 className="text-center event-title">{post.title.rendered}</h4>
      </div>
    ))}
      </Col>
      </Row>
      </Container>


    </div>
  );
}
}

export default Standuppage;
