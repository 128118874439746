import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
//Images
import MikeAtShow from '../images/mike-at-show.jpg';
//Includes
import MailchimpSubscribe from '../includes/MailchimpSubscribe.js';

class Homepage extends Component {
  render() { return(
    <div className="Home">

    <Helmet>
    <title>Mike Yanek - Liverpool based comedian & podcaster</title>
    <meta name="description" content="Comedian, podcaster and a shameless self promoter based in Liverpool UK. You can catch me at a gig across the North or listen to my podcast on several streaming platforms." />
    <meta property="og:title" content="Mike Yanek - Liverpool based comedian & podcaster" />
    <meta property="og:description" content="Comedian, podcaster and a shameless self promoter based in Liverpool UK. You can catch me at a gig across the North or listen to my podcast on several streaming platforms." />
    <meta property="og:image" content="/og-image.jpg"/>
    </Helmet>

    <img src={MikeAtShow} className="homepage-header-image" alt="Mike Yanek at " />
    <Container>
    <Row>
    <Col>
    <div className="spotify-iframe">
    <iframe title="Mike Yanek Spotify Podcast" src="https://open.spotify.com/embed-podcast/show/4i0lQRjXapPwL9WHhurZnq" width="100%" height="160" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    </div>
    </Col>
    </Row>
    </Container>
    <Container>
    <Row className="book-row">
    <Col lg="8" className="text-center paragraphs">
    <h4 className="mb-4 ">About Me</h4>
    <p>Comedian, podcaster and a shameless self promoter. It's me Mike! An adorable ball of energy, screaming in a crazy accent, travelling the UK telling jokes and being a goofball on stage, while having the absolute time of my life. If I'm in a venue near you, come and see me be outrageous, preposterous and eccentric on stage, it is worth it.</p>
    </Col>
    <Col lg="4" className="text-center mt-3">
    <h4 className="mb-5">Sign Up To My Mailing List</h4>
    <MailchimpSubscribe/>
    </Col>
    </Row>
    <Row>
      <Col lg="8">

      </Col>
    </Row>
    </Container>

    </div>
  );
}
}

export default Homepage;
