import React, { Component } from 'react';
//Packages
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
//Images
import MikeYanekShowLogo from '../images/mike-yanek-show-logo.svg';

class Podcastspage extends Component {
  render() { return(
    <div className="Home">

    <Helmet>
    <title>Mike Yanek - Liverpool based comedian & podcaster</title>
    <meta name="description" content="If you can't get enough of my voice, you can dive into my podcasts on Podbean and several other platforms. Listen to me talk about life, dates and pizza among other things." />
    <meta property="og:title" content="Mike Yanek - Liverpool based comedian & podcaster" />
    <meta property="og:description" content="If you can't get enough of my voice, you can dive into my podcasts on Podbean and several other platforms. Listen to me talk about life, dates and pizza among other things." />
    <meta property="og:image" content="/og-image.jpg"/>
    </Helmet>
    <Container>
    <Row>
    <Col md="7" className="mt-3">
    <h4 className="text-center">Podcasts</h4>
    <ResponsiveEmbed a21by9>
    <iframe title="The Mike Yanek Show" id="multi_iframe" src="https://www.podbean.com/media/player/multi?playlist=http%3A%2F%2Fplaylist.podbean.com%2F6963020%2Fplaylist_multi.xml&vjs=1&kdsowie31j4k1jlf913=ebb6417f7953a968b856f1d833bd1b4eca96cfb7&size=430&skin=&episode_list_bg=%23ffffff&bg_left=%23000000&bg_mid=%230c5056&bg_right=%232a1844&podcast_title_color=%23c4c4c4&episode_title_color=%23ffffff&auto=0&share=0&fonts=Helvetica&download=0&rtl=0&show_playlist_recent_number=10&pbad=1"></iframe>
    </ResponsiveEmbed>
    </Col>
    <Col md="5" className="text-center mt-3">
    <img src={MikeYanekShowLogo} className="img-fluid mb-5" alt="Mike Yanek Show Podcast Logo"/>
    <h5 className="text-center mb-3">Other Places to Listen to My Podcast</h5>
    <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://themikeyanekshow.podbean.com/" className="podcast-button" block>Podbean</Button><br/>
    <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://open.spotify.com/show/4i0lQRjXapPwL9WHhurZnq" className="podcast-button" block>Spotify</Button><br/>
    <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://apple.co/2tOQvfw" className="podcast-button" block>iTunes</Button><br/>
    <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.youtube.com/channel/UCaqSNurL3xHioYcaMkjUX1A" className="podcast-button" block>YouTube</Button><br/>
    <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.listennotes.com/podcasts/the-mike-yanek-show-themikeyanekshow-l18YSCzoPZ_/" className="podcast-button" block>ListenNotes</Button><br/>
    <Button variant="danger" target="_blank" rel="noopener noreferrer"  size="lg" href="https://www.stitcher.com/podcast/the-mike-yanek-show" className="podcast-button" block>Stitcher</Button><br/>
    </Col>
    </Row>
    <Row className="book-row">
    <Col className="text-center mt-3">
    <h3>About the Show</h3>
    <p>How you doing mate? I hope you're doing well. Just a tiny fella, mad ball of energy, the long lost brother of Taz from Looney Toons. Born in a land far away, moved to the UK at the tender age of 21, my childhood was wild, awesome and sometimes lonely, I was raised by the TV, as the fascination with showmanship begun at an early age, when I first saw American pop culture, late night talk shows and stand-up... I was completely hypnotised by it. But time flies fast... Now I'm an old geezer in my early 30's, and I do need to occupy myself so I do comedy and write books, I have a lot to say and rant about, and that's why this podcast is a solo one.</p>
    </Col>
    </Row>
    </Container>
    </div>
  );
}
}

export default Podcastspage;
