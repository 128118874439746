import React from 'react';
//Pages
import Homepage from './pages/Homepage.js';
import Standuppage from './pages/Standuppage.js';
import Bookspage from './pages/Bookspage.js';
import Podcastspage from './pages/Podcastspage.js';
import Privacypage from './pages/Privacypage.js';
import ContactPage from './pages/Contactpage.js';
//Structure
import Navigationbar from './structure/navigationbar.js';
import Footer from './structure/footer.js';
import Socialbar from './structure/socialbar.js';
//Router
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

//Navigation - Router and footer. The content between the switch renders as functions.
export default function App() {
  return (
    <div className="App">
    <Router>
    <Socialbar/>
    <Navigationbar/>
    <Switch>
    <Route exact path="/" component={Homepage}/>
    <Route path="/standup/" component={Standuppage}/>
    <Route path="/books/" component={Bookspage}/>
    <Route path="/podcasts/" component={Podcastspage}/>
    <Route path="/privacy-policy/" component={Privacypage}/>
    <Route path="/contact/" component={ContactPage}/>
    </Switch>
    </Router>
    <Footer/>
    </div>
  );
}

//To Reinstall the Blog
//import Blog from './pages/Blog.js';
//import Blogpost from './pages/Blogpost.js';
//and into the route
//<Route exact path="/blog/" component={Blog}/>
//<Route path="/blog/:slug/" component={Blogpost}/>
